.coverImage {
    height: 15rem;
    width: 100%;
    object-fit: cover;
}

.details {
    padding-inline: 2rem;
}
.bio {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  white-space: normal !important;
  max-width: 100% !important; 
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.profileImage {
    width: 10rem;
    height: 10rem;
    border-radius: 5rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    bottom: 5rem;
    border: 5px solid #424242;
}

.uploadProfileBtn {
    background-color: var(--primary-color);
    color: white;
    height: 2.5rem;
    width: 2.5rem;
    font-size: 2em;
    border-radius: 50%;
    cursor: pointer;
    margin-top: 1rem;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: -4rem;
}

.mainDetails h3 {
    margin-bottom: 1rem;
}

.mainDetails p {
    color: grey;
}

.followingDetails {
    display: flex;
    flex-direction: row;
}

.followingDetailsItem {
    padding-inline: 2rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.following {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.following div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nameText {
    font-size: 1.5em;
}

.nameInput {
    padding: .5rem;
    border: 1px solid #d3d3d3;
    outline: none;
    border-radius: 5px;
    width: 6rem;
}

.bioInput {
    width: 100%;
    padding: .5rem;
    border: 1px solid #d3d3d3;
    outline: none;
    border-radius: 5px;
}

.editIcon {
    color: grey;
    padding: .2rem;
    cursor: pointer;
}

@media only screen and (max-width: 500px) {
    .mainDetails {
        flex-direction: column;
    }

    .metaDetails {
        padding-bottom: 1rem;
    }

    .followingDetails {
        font-size: 12px;
        border: 1px solid #c4c4c4;
        padding: .5rem;
    }
}