.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.text {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 4rem;
}

.btnMainDiv {
  text-align: center;
}

.goBackBtn {
  background-color: tomato;
  color: #fff;
  padding: 0.5rem 2rem;
  cursor: pointer;
}

.noText {
  text-align: center;
  font-size: 7rem;
}
