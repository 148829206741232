/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap"); */
@import url("https://your-font-service.com/path-to-avenir-fonts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Lato", sans-serif; */
  font-family: "Avenir", sans-serif;
}

:root {
  --primary-color: #f76843;
  --navbar-height: 91px;
}

.navbar {
  height: var(--navbar-height);
  background-color: var(--primary-color);
  position: fixed; /* Stays fixed at the top */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensures navbar stays on top */
}

.main-content {
  margin-top: var(--navbar-height); /* Push content below the navbar */
  height: calc(100vh - var(--navbar-height)); /* Use remaining space after navbar */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.error-text {
  color: #ef5350;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: #fff;
}
*::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #eee;
}
