.main {
  padding-top: 1rem;
}

.logo {
  width: 100%;
  max-height: 148px;
  margin-bottom: 30px;
}

.emailcontentDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 50px;
}

.emailUsContentImg {
  width: 55%;
}

.emergencycontentDiv {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.emergencyContentImg {
  width: 55%;
}
