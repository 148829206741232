.container {
  display: flex;
  padding: 1rem;
  width: 100%;
}

.noRecordFoundMainDiv {
  align-items: center;
  justify-content: center;
  margin: 0px auto;
}

.ThubCaptionText p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: "Avenir", sans-serif;
}

.noRecordFoundText {
  padding: 4rem;
  text-align: center;
  font-size: 2rem;
  opacity: 0.3;
}

.noRecordFoundImag {
  width: 100%;
  text-align: center;
}

.lectureThumbnailItem {
  width: 100%;
  position: relative;
}

.thumImageDiv {
  position: relative;
  padding-top: 56.25%;
  cursor: pointer;
}

.thumImage {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  box-shadow: 4px 0px 10px #ada0a0;
  border-radius: 10px 11px 0 0;
}

.teacherDetailsLeft {
  position: absolute;
  z-index: 9;
  right: 12px;
  top: 10px;
}

.profileImage {
  border-radius: 1.5rem;
  object-fit: cover;
  margin-right: 1rem;
  cursor: pointer;
  height: 47px !important;
  width: 48px !important;
}

.nameText {
  color: white;
  font-family: "Avenir", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  margin-top: 10px;
}

.ThubCaptionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: "Avenir", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  max-width: calc(100% - 55px);
  margin-left: auto;
  flex: 0 0 calc(100% - 55px);
}

.bottomTab {
  position: static;
  background-color: #f76843;
  height: auto;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 0 0 10px 10px;
  box-shadow: 4px 0px 10px #ada0a0;
}

.bottomUserImage {
  display: flex;
  align-items: center;
}

.ratingDiv {
  margin-bottom: 6px;
  margin-top: 1px;
  position: absolute;
  right: 0;
  bottom: 6px;
}

.centeText {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0;
  display: none;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.avatarLoader {
  margin-top: -80px !important;
}

@media only screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: inherit;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 100% !important;
    margin: 0 auto;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .lectureThumbnailItem {
    margin: 0 auto;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .lectureThumbnailItem {
    margin-bottom: 36px;
    width: 100% !important;
    margin: 0 auto;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .lectureThumbnailItem {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 1366) {
  .container {
    display: contents !important;
    padding: 1rem;
    width: 100%;
  }
  .lectureThumbnailItem {
    margin-bottom: 36px;
    width: 100% !important;
    margin: 0 auto;
  }
}
