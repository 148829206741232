.mainDiv {
  position: relative;
  overflow: hidden !important;
}

.videoMainDiv {
  width: 100%;
  height: 100%;
  position: relative;
}

.thumImage {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  object-fit: contain;
  cursor: pointer;
}

.closeIconWrapper {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 15px;
  position: absolute;
  right: 0;
  top: 5px;
}

.teacherDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid grey;
  padding: 20px 15px 20px;
}

.teacherDetailsTitleft {
  display: flex;
  flex-direction: row;
}

.teacherDetailsRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 20px;
}

.labelRateText {
  text-align: right;
  color: #686868;
  cursor: pointer;
  font-size: 21px;
}

.video {
  height: 25rem;
  width: 100%;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard_video {
  height: 30rem !important;
  width: 100%;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}


.videoDetailsWrapper {
  padding: 1rem;
}

.videoDetails {
  padding-top: -10px !important;
}

.videoTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.videoTitleText {
  font-size: 24px !important;
  font-weight: 400;
  letter-spacing: 0em;
  color: #000 !important;
  font-weight: bold !important;
}

.commentBar {
  background-color: #c4c4c4;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  margin-inline: 1rem;
  margin-bottom: 1.2rem;
  border-radius: 10px;
}

.commentBar input {
  background-color: #c4c4c4;
  width: 100%;
  border: none;
  outline: none;
  margin-right: 2rem;
}

.comentText {
  color: grey;
  font-family: "Cabin", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.description {
  color: grey;
  font-family: "Cabin", sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: auto;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.commentText {
  padding-block: 1rem;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: #686868;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 1px;
}

.closeIconDiv {
  position: sticky;
  top: 0px;
  z-index: 9;
  background: white;
  padding-bottom: 0;
  border-bottom: 1px solid grey;
  height: "100px";
}

.video {
  height: 20rem;
  width: 100%;
  background-color: #c4c4c4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacherDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 1rem;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid grey;
  flex-wrap: wrap;
  padding: 10px;
}

.closeIconWrapper {
  position: absolute;
  right: 0;
}

.closeIconWrapper svg {
  font-size: 30px;
}

.videoTitleText {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0em;
  color: #686868;
  margin-bottom: 1px;
}

.description {
  color: grey;
  font-family: "Cabin", sans-serif;
  font-size: 12px;
  font-weight: 400;
  width: 25%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.closeIconDiv {
  position: sticky;
  top: 0px;
  z-index: 9;
  background: white;
  padding-bottom: 0;
  border-bottom: 1px solid grey;
  height: auto;
}

.aboutMe {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  font-family: "Lato", sans-serif !important;
  /* transition: all 0.3s ease-in-out; */
}

.aboutMe span {
  text-align: right;
  font-size: 17px;
  font-weight: 400 !important;
  color: #686868 !important;
  font-family: "Lato", sans-serif !important;
}

.descriptionDeatils {
  color: grey;
  font-weight: bold;
  font-size: 16px;
  font-family: "Lato", sans-serif !important;
  margin-bottom: 10px;
}

.descriptionDeatils span {
  text-align: right;
  font-size: 17px;
  font-weight: 400 !important;
  color: #686868 !important;
  font-family: "Lato", sans-serif !important;
}

.showMoreText {
  cursor: pointer;
  margin-left: 13px;
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: "Cabin", sans-serif;
}

.paper {
  padding: 20px;
  text-align: center;
  color: red;
  margin-top: 16px;
}

.readMoreText {
  cursor: pointer;
  margin-left: 13px;
  font-size: 1em;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: "Cabin", sans-serif;
}

.descriptionDivider {
  margin: 0 auto !important;
  height: 100% !important;
  background: #c4c4c4;
  width: 1px;
}

.descriptionInfo {
  width: 80%;
}

.moreInfo {
  padding-block: 1rem;
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  color: #686868;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 1px;
  float: left;
  white-space: nowrap !important;
}

.infoPaper {
  width: 100%;
  border-radius: 15px !important;
  background-color: rgb(23, 132, 233) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  position: relative;
}

.paper {
  border-radius: 10px;
  margin-right: 30px;
  background-color: rgb(23, 132, 233) !important;
}

.mainH6 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mainH6 h6 {
  font-family: "Avenir", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
  margin-right: 3px;
}

.ldtiltle {
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.ldcaption {
  width: 100%;
  font-family: "Avenir", sans-serif;
  font-size: 14px;
  color: white;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.readMoreDescription {
  text-align: left;
  font-size: 17px;
  font-weight: 400 !important;
  color: #686868 !important;
  font-family: "Lato", sans-serif !important;
}

.starIcon {
  color: #fcd925;
  margin-left: 0.3rem;
  height: 20px;
  width: 20px;
}

.ratingText {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 15px;
}

.scDivider {
  margin-top: 25px !important;
  margin-bottom: 0px;
  border-bottom: 1px solid #c4c4c4;
  height: 1px;
}

.ratingDiv {
  margin-top: auto;
  padding-top: 8px;
}

.viewRatingText {
  font-size: 0.9rem;
  color: white;
}

.noDataFoundMainDiv {
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  opacity: 0.3;
}

.nodataText {
  padding: 4rem;
  text-align: center;
  font-size: 2rem;
}

.lectureThumbnailItem {
  padding: 0 10px;
  padding-bottom: 36px;
  position: relative;
}

.imageMainDiv {
  position: relative;
  padding-top: 56.25%;
  cursor: pointer;
}

.thumbnailImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  box-shadow: 4px 0px 10px #ada0a0;
  border-radius: 10px 11px 0 0;
}

.bottomTab {
  position: static;
  background-color: #f76843;
  height: auto;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 4px 0px 10px #ada0a0;
}

.bottomDiv {
  display: flex;
}

.profileImage {
  border-radius: 1.5rem;
  object-fit: cover;
  margin-right: 1rem;
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
}

.ThubCaptionText {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  max-width: calc(100% - 55px);
  flex: 0 0 calc(100% - 55px);
  cursor: pointer;
}

.ThubCaptionText h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: "Avenir", sans-serif;
}

.ratingDiv {
  margin-bottom: 2px;
}

.nameText {
  color: white;
  font-family: "Avenir", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  margin-top: 19px;
  margin-left: -39px;
}

.profileNameText {
  font-size: 24px;
  cursor: pointer;
  font-family: "Avenir", sans-serif;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0em;
  color: #686868;
  white-space: nowrap !important;
}

.paperMainDiv {
  max-height: 500px;
  /* Adjust this height based on your layout requirements */
  overflow-y: auto;
  /* Enables vertical scrolling */
  padding-right: 10px;
  /* Optional: Adds some padding on the right side */
}

.teacherDetailsLeft {
  position: absolute;
  z-index: 9;
  right: 12px;
  bottom: 33px;
  cursor: pointer;
}

.moreIcon {
  display: flex;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .mainDiv {
    padding: 0;
  }

  .commentText {
    padding-block: 1rem;
    font-family: "Cabin", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #686868;
  }
}

@media only screen and (max-width: 320px) {
  .videoTitleText {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0em;
    color: #686868;
    margin-bottom: 1px;
  }

  .teacherDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid grey;
    flex-wrap: wrap;
    padding: 10px;
  }

  .profileImage {
    border-radius: 1.5rem;
    object-fit: cover;
    margin-right: 1rem;
    cursor: pointer;
    height: 45px !important;
    width: 45px !important;
    margin-top: 0;
  }

  .commentText {
    padding-block: 1rem;
    font-family: "Cabin", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #686868;
  }
}

@media only screen and (max-width: 375px) {
  .teacherDetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 1rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid grey;
    flex-wrap: wrap;
    padding: 10px;
  }

  .profileImage {
    border-radius: 1.5rem;
    object-fit: cover;
    margin-right: 1rem;
    cursor: pointer;
    height: 45px !important;
    width: 45px !important;
    margin-top: 0;
  }

  .closeIconDiv {
    position: sticky;
    top: 0px;
    z-index: 9;
    background: white;
    padding-bottom: 0;
    border-bottom: 1px solid grey;
    height: auto;
  }

  .videoTitleText {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #686868;
    margin-bottom: 1px;
  }

  .description {
    color: grey;
    font-family: "Cabin", sans-serif;
    font-size: 9px;
    font-weight: 400;
    width: 25%;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
  }

  .commentText {
    padding-block: 1rem;
    font-family: "Cabin", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #686868;
  }
}

@media only screen and (max-width: 1180px) {
  .mainDiv {
    padding: 1rem;
  }
}

@media only screen and (max-width: 1366px) {
  .videoTitleText {
    font-size: 0.9rem !important;
    font-weight: 400;
    letter-spacing: 0em;
    color: #686868;
    margin-bottom: 1px;
  }
}

@media (min-width: 1440px) {
  .videoTitleText {
    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 0em;
    color: #686868;
    margin-bottom: 1px;
  }

  .ldtiltle {
    font-family: "Avenir", sans-serif;
    font-size: 14px;
    color: white;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}

@media (min-width: 820px) {
  .ldtiltle {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    color: white;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ratingDiv {
    margin-top: auto;
    padding-top: 8px;
  }
}