.container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
}

.avatarLoader {
  margin-top: -80px !important;
}

.thumbnailImage {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  box-shadow: 4px 0px 10px #ada0a0;
  border-radius: 10px 11px 0 0;
}

.captionText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lectureThumbnailItem {
  padding: 0 10px;
  width: 17rem;
  padding-bottom: 36px;
  position: relative;
}

.noDataFoundMainDiv {
  align-items: center;
  justify-content: center;
  margin: 0px auto;
  opacity: 0.3;
}

.imageMainDiv {
  position: relative;
  padding-top: 56.25%;
  cursor: pointer;
}

.moreIcon {
  display: flex;
  cursor: pointer;
}

.bottomDiv {
  display: flex;
}

.ratingDiv {
  margin-bottom: 2px;
}

.profileImage {
  border-radius: 1.5rem;
  object-fit: cover;
  margin-right: 1rem;
  cursor: pointer;
  height: 32px !important;
  width: 32px !important;
}

.teacherDetailsLeft {
  position: absolute;
  z-index: 9;
  right: 12px;
  bottom: 35px;
  cursor: pointer;
}

.nameText {
  color: white;
  font-family: "Avenir", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  margin-top: 19px;
  margin-left: -39px;
}

.ThubCaptionText {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0em;
  max-width: calc(100% - 55px);
  flex: 0 0 calc(100% - 55px);
  cursor: pointer;
}

.ThubCaptionText h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-family: "Avenir", sans-serif;
}

.bottomTab {
  position: static;
  background-color: #f76843;
  height: auto;
  width: 100%;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  box-shadow: 4px 0px 10px #ada0a0;
}

.centeText {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  margin: 0;
  display: none;
}

.nodataText {
  padding: 4rem;
  text-align: center;
  font-size: 2rem;
}

@media only screen and (max-width: 1440px) {
  .teacherDetailsLeft {
    position: absolute;
    z-index: 9;
    right: 12px;
    bottom: 33px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 50% !important;
  }
}
@media (max-width: 1200px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1250px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1260px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1270px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1300px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1350px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }
}

@media (max-width: 1600px) {
  .teacherDetailsLeft {
    position: absolute;
    z-index: 9;
    right: 12px;
    bottom: 34px;
    cursor: pointer;
  }
}

@media (max-width: 1536px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .nameText {
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-left: -39px;
    position: relative;
    bottom: -4px;
  }
  .teacherDetailsLeft {
    position: absolute;
    z-index: 9;
    right: 12px;
    bottom: 30px;
    cursor: pointer;
  }
}
@media (max-width: 1366px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .nameText {
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-left: -39px;
    position: relative;
    bottom: -1px;
  }
  .teacherDetailsLeft {
    position: absolute;
    z-index: 9;
    right: 12px;
    bottom: 32px;
    cursor: pointer;
  }
}

@media (max-width: 1280px) {
  .ThubCaptionText h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 16px;
    min-height: 40px;
    white-space: break-spaces;
  }
  .ratingDiv {
    margin-bottom: 0;
    margin-top: 65px;
  }
  .nameText {
    color: white;
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0em;
    margin-top: 20px;
    margin-left: -39px;
    position: relative;
    bottom: -21px;
  }
  .teacherDetailsLeft {
    position: absolute;
    z-index: 9;
    right: 12px;
    bottom: 30px;
    cursor: pointer;
  }
}

@media (min-width: 400px) and (max-width: 700px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .lectureThumbnailItem {
    margin-bottom: -4px;
    width: 100% !important;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 100% !important;
  }
}

@media (max-width: 834px) and (max-width: 1180px) {
  .container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 50% !important;
  }
}

@media (max-width: 100px) and (max-width: 1180px) {
  .container {
    display: flex;
    flex-direction: inherit;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 100% !important;
  }
}

@media (max-width: 1125px) and (min-width: 800px) {
  .nodataText {
    padding: 2rem;
    text-align: center;
    font-size: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .lectureThumbnailItem {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1024px) and (max-width: 1366) {
  .lectureThumbnailItem {
    margin-bottom: 36px;
    width: 100% !important;
  }
}
