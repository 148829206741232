.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.container label {
  color: grey;
}

.seperator {
  background-color: none;
  height: 5px;
  width: 5px;
  border-radius: 50%;
  margin-inline: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .star {
    font-size: 1rem;
  }
  .seperator {
    background-color: none;
    height: 7px;
    width: 8px;
    border-radius: 50%;
    margin-inline: 0.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .viewRatingText {
    text-align: center;
  }
  .seperator {
    background-color: none;
    height: 7px;
    width: 8px;
    border-radius: 50%;
    margin-inline: 0.5rem;
  }
  .ratingCount {
    margin-left: 1rem;
  }
}
