.container {
    padding-top: 80px;
    /* padding-inline: 4rem; */
    height: calc(100vh - 101px);
  }
  
  .tagDiv {
    font-size: 40px;
  }

  .loginText {
    display: flex;
    justify-content: space-around;
    flex-direction:  column;
  }
  
  .infoText {
    text-align: left;
    margin-bottom: 1rem;
  }

  .infoText2 p {
    font-style: italic;
    color: #353535;
    font-size: 20px;
    letter-spacing: 4px;
    line-height: 2rem;
  }
  
  .infoText p {
    font-style: italic;
    color: #353535;
    font-size: 20px;
    letter-spacing: 6px;
    line-height: 2rem;
  }
  
  .googleSSO {
    transform: scale(2.0);
    transform-origin: 0 0;
    z-index: 2;
    max-width: fit-content;
  }
  
  .GridContainer {
    padding-top: 4rem;
    padding-inline: 4rem;
  }
  
  .loginDetails {
    margin-top: 45px;
  }

  .loginDetails p {
    letter-spacing: 2px;
    line-height: 1rem;
    font-style: italic;
    color: #353535;
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
  
  .loginDetails p :hover {
    text-decoration: underline;
  }
  
  .GridSmallContainer {
    padding-top: 4rem;
    padding-inline: 4rem;
  }
  
  .GridMadiumContainer {
    padding-top: 4rem;
    padding-inline: 4rem;
  }
  
  .GridExtraSmallContainer {
    padding-top: 4rem;
    padding-inline: 4rem;
  }

  .learnWayImg img {
    height: 300px;
  }

  @media (max-width: 1550px) {
    .infoText2 p {
      width: 250px;
    }
  }

  @media (max-width: 1280px) {
    .learnWayImg img {
      height: 225px;
      width: 275px;
    }
  }

  @media (max-width: 930px) {
    .googleSSO {
      transform: scale(1.8);
      transform-origin: 0 0;
    }
    .loginDetails {
      margin-top: 40px;
    }
  }

  @media (max-width: 821px) {
    .container {
      height: calc(100vh - 80px);
    }
    .googleSSO {
      transform: scale(1.4);
      transform-origin: 0 0;
    }
    .loginDetails {
      margin-top: 20px;
    }
  }
  