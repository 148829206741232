.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 3rem;
  padding: 4rem;
}

.teacher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  /* margin-right: 120px;
  margin-left:65px; */
}

.profileImage {
  height: 12rem;
  width: 12rem;
  margin-bottom: 1rem;
  border-radius: 50%;
  border: 5px solid rgb(66, 66, 66);
  cursor: pointer;
  object-fit: cover;
}

@media only screen and (max-width: 500px) {
  .container {
    padding: 2rem;
    gap: 2rem;
  }

  .image {
    width: 8rem;
    height: 8rem;
  }

  .profileImage {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .nameText {
    font-size: 11px;
  }
  .subject {
    font-size: 11px;
  }
}

@media only screen and (max-width: 320px) {
  .profileImage {
    height: 4rem;
    width: 4rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .nameText {
    font-size: 9px !important;
  }
  .subject {
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 768px) {
  .nameText {
    font-size: 11px;
  }
  .subject {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1600px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0;
    padding: 4rem;
  }
  .profileImage {
    height: 10rem;
    width: 10rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1440px) {
  .profileImage {
    height: 8rem;
    width: 8rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .teacher {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 61px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1280px) {
  .profileImage {
    height: 8rem;
    width: 8rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .teacher {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 38px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .profileImage {
    height: 12rem;
    width: 12rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .teacher {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 107px;
    margin-left: 107px;
  }
}

@media only screen and (max-width: 820px) {
  .profileImage {
    height: 10rem;
    width: 10rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    border: 5px solid rgb(66, 66, 66);
    cursor: pointer;
    object-fit: cover;
  }

  .teacher {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    margin-left: 50px;
  }
}
