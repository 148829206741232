.navbar {
  background-color: #fff;
  box-shadow: 0px -3px 10px 0px rgb(0 0 0 / 50%);
  padding-inline: 1rem;
  height: var(--navbar-height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10000;
  height: 91px;
  width: 100%;
}

.logo {
  height: 44px;
  /* margin-top: 1rem; */
  margin-right: 1rem;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBar {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 2px 10px;
  border-radius: 12px;
  width: 30rem;
}

.searchBar svg {
  height: 25px;
  width: 25px;
}

.searchBar input {
  outline: none;
  border: none;
  background-color: #f2f2f2;
  padding: 0.7rem 0.5rem;
  font-size: 15px;
  width: 100%;
}

.searchIcon {
  color: "grey";
  margin-right: 0.5rem;
  cursor: pointer;
}

.navList {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.navItem {
  padding-inline: 0.5rem;
  cursor: pointer;
}

.navLink {
  color: black;
  text-decoration: none;
}

.navIcon {
  color: "grey";
  margin-top: 4px;
}

.menuBtn,
.mobileSearchBar {
  display: none;
}

.menu ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  padding-top: 2rem;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.97);
  z-index: 20;
}

.menu li {
  padding: 1rem;
}

.tabContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Tablet */
@media only screen and (max-width: 1052px) {
  .navbar {
    box-shadow: none;
  }

  .logo {
    height: 2.7rem;
    margin-top: 0;
  }

  .menuBtn {
    display: block;
  }

  .navDesktop {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .searchBar {
    width: auto;
  }
}

@media only screen and (max-width: 768px) {
  .searchBar {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    padding: 2px 10px;
    border-radius: 5px;
    width: auto;
    flex-grow: 1;
  }
  .left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
  .logo {
    margin-top: 0;
  }
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .logo {
    height: 2rem;
    margin-top: 0;
  }

  .menuBtn,
  .mobileSearchBar {
    display: block;
  }

  .navDesktop,
  .desktopSearchBar {
    display: none;
  }

  .mobileSearchBar {
    padding-inline: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .searchBar {
    width: 100%;
  }
}
