.container {
  background: white;
  color: black;
  border-radius: 1em;
  padding: 1em;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  width: 50%;
}

.logo {
  height: 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
  /* display: block;
  margin-left: auto;
  margin-right: auto; */
}

.center {
  text-align: center;
  margin-left: 2rem;
}

.mainText {
  text-align: center;
  font-size: 40px;
  color: #393939;
  font-weight: 700 !important;
  font-family: "Avenir", sans-serif;
}

.description {
  font-family: "Avenir", sans-serif;
  font-size: 40px;
  font-weight: 300 !important;
  line-height: 60px;
  letter-spacing: normal;
  text-align: center;
  color: #7b7f83;
}

.description span {
  font-weight: bold;
}
