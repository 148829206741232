.container {
  padding: 0.5rem 7rem;
}

.heroText {
  display: block;
  font-size: 72px;
  font-weight: 600;
  padding-block: 3rem;
  padding-bottom: 4rem;
  text-transform: uppercase;
}

.actionBtn {
  padding: 1rem 3rem;
  text-decoration: none;
  color: grey;
  text-transform: uppercase;
  border-radius: 5px;
}

.loginBtn {
  color: #fff;
  background-color: tomato;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0.5rem 3rem;
  }
  .heroText {
    font-size: 3em;
    font-weight: 600;
    padding-top: 5rem;
    padding-bottom: 4rem;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    padding: 0.5rem 2rem;
  }

  .heroText {
    font-size: 2rem;
  }
  .actionBtn {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 375px) {
  .container {
    padding: 0.5rem 2rem;
  }
  .actionBtn {
    padding: 1rem 2rem;
  }
}

@media only screen and (max-width: 320px) {
  .container {
    padding: 0.5rem 2rem;
  }
  .actionBtn {
    padding: 1rem 1rem;
  }
}
