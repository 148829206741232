.coverImage {
  height: 9rem;
  width: 100%;
  object-fit: cover;
}
.bio {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%; 
  font-size: 16px;
  line-height: 1.5;
}
.details {
  padding-inline: 2rem;
  padding-bottom: 0;
}

.top {
  position: relative;
  bottom: 5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.top div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 3rem;
}

.top h2 {
  font-weight: 500;
}

.top button {
  padding: 0.4rem 1rem;
  background-color: #fcd925;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}

.profileImage {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid #424242;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.uploadProfileBtn {
  background-color: var(--primary-color);
  color: white;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 2em;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 1rem;
  position: absolute;
  /* bottom: 0;
  right: 0; */
  bottom: 0.5rem;
  left: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .uploadProfileBtn {
    position: absolute;
    bottom: 2.5rem;
    left: 5.5rem;
  }
}

.mainDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: -4rem;
}

.mainDetails h3 {
  margin-bottom: 1rem;
}

.mainDetails p {
  color: grey;
}

.createBtn span {
  font-size: 18px;
  text-transform: capitalize;
}

.editBtn span {
  font-size: 18px;
  text-transform: capitalize;
}

.aboutText h4 {
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
}

.aboutText p {
  font-family: "Avenir", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3a3a;
}

.teachSubjectDetails h4 {
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.followingDetails {
  display: flex;
  flex-direction: row;
}

.nameText h2 {
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
}

.followingDetailsItem {
  padding-inline: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.followingDetailsItem h3 {
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

.followingDetailsItem label {
  font-family: "Avenir", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.postDetail {
  margin-bottom: 0.5rem;
}

.postDetail b {
  font-family: "Avenir", sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.postDetail span {
  color: #1784e9;
}

@media only screen and (max-width: 500px) {
  .details {
    padding-inline: 1rem;
  }

  .mainDetails {
    flex-direction: column;
  }

  .metaDetails {
    padding-bottom: 1rem;
  }
  .top {
    flex-direction: column;
    align-items: flex-start;
  }

  .top div {
    align-items: flex-start;
    padding: 0;
    justify-content: center;
  }

  .profileImage {
    width: 8rem;
    height: 8rem;
  }
}

.followBtn {
  margin-left: 30px;
  height: 30px;
}

@media only screen and (max-width: 768px) {
  .followingDetails {
    text-align: center;
  }
  .followingDetailsItem h3 {
    font-family: "Avenir", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .nameText h2 {
    font-family: "Avenir", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .followBtn {
    margin-left: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 1053px) {
  .followingDetailsItem h3 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .nameText h2 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1111px) {
  .followingDetailsItem h3 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .nameText h2 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
  }
}

@media only screen and (max-width: 1134px) {
  .followingDetailsItem h3 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .nameText h2 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media only screen and (max-width: 1147px) {
  .followingDetailsItem h3 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
  .nameText h2 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
  }
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
}

@media only screen and (max-width: 320px) {
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .followBtn {
    margin-left: 30px;
    height: 30px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .followBtn {
    margin-left: 30px;
    height: 30px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 1024px) {
  .followBtn {
    margin-left: 30px;
    height: 30px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 540px) {
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .followBtn {
    margin-left: 30px;
    height: 30px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 280px) {
  .teachSubjectDetails h4 {
    font-family: "Avenir", sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .followBtn {
    margin-left: 30px;
    height: 30px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 1280px) {
  .top button {
    padding: 0.4rem 1rem;
    background-color: #fcd925;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    font-size: 12px;
  }
}
