.navbar {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -3px 10px 0px rgb(0 0 0 / 50%);
}

.logo {
  height: 3rem;
  margin-top: 1rem;
}

.navList {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.navItem {
  padding-inline: 1rem;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
  font-size: 1.3em;
  font-weight: 100;
  color: #000;
  text-transform: uppercase;
}

.navLink:hover {
  font-weight: 400;
}

.loginBtn {
  padding: 1rem;
  background-color: var(--primary-color);
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 7px;
  color: #fff;
  font-weight: 300;
}

.active {
  color: var(--primary-color);
}

.menuBtn {
  display: none;
}

@media only screen and (max-width: 425px) {
  .navbar {
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    height: 1rem;
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 2rem;
    margin-top: 0;
  }
}

/* Tablet */
@media only screen and (max-width: 1052px) {
  .menuBtn {
    display: block;
  }

  .navDesktop {
    display: none;
  }

  .navMobile {
    display: block;
    position: relative;
    z-index: 1000;
  }

  .navList {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    padding-top: 3rem;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.97);
  }

  .navItem {
    padding: 1rem 0;
  }
}
