.navbar {
  background-color: #fff;
  box-shadow: 0px -3px 10px 0px rgb(0 0 0 / 50%);
  padding-inline: 1rem;
  height: var(--navbar-height);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10000;
  height: 90px;
  width: 100%;
}

.logo {
  height: 3rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navList {
  display: flex;
  flex-direction: row;
  list-style: none;
}

.navItem {
  padding-inline: 0.5rem;
  cursor: pointer;
}

.navLink {
  color: black;
  text-decoration: none;
}

.navIcon {
  color: "grey";
}

/* Tablet */
@media only screen and (max-width: 1052px) {
  .navbar {
    box-shadow: none;
  }
}

/* Mobile */
@media only screen and (max-width: 500px) {
  .logo {
    height: 2rem;
    margin-top: 0;
  }
}

@media only screen and (max-width: 375px) {
  .logo {
    height: 2rem;
    margin-top: 0;
  }
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 2rem;
    margin-top: 0;
  }
}
