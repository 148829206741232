.container {
  padding: 0;
  height: calc(100vh - var(--navbar-height));
}

.welcomeText {
  margin-bottom: 1.5rem;
  color: var(--primary-color);
  font-size: 1.5em;
  font-weight: 100;
}

.institutionDetails {
  margin-bottom: 2rem;
}

.iconText {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 0.5rem;
  color: var(--primary-color);
}

.headerText {
  color: rgb(73, 73, 73);
  margin-top: 25px;
}

.institutionName {
  font-size: 2em;
}

.noDataDiv {
  padding-block: 2rem;
  text-align: center;
}

.noDataDiv p {
  font-weight: 100;
  color: grey;
  opacity: 0.3;
}

.noDataImg {
  /* width: 10rem;
    height: 14rem; */
  aspect-ratio: 1/1;
  width: 30%;
  object-fit: cover;
}

.address {
  font-weight: 100;
}

.institutionList {
  list-style: none;
}

.studentItem {
  border-bottom: 1px solid #d3d3d3;
  padding: 1rem 0.5rem;
  cursor: pointer;
  display: flex;
}

.studentItem span {
  margin-left: 1rem;
}

.studentItem:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.gridBoder {
  border-right: 1px solid rgb(211, 211, 211);
  padding: 20px;
  height: 100%;
  overflow: auto;
}

.containerGrid {
  height: 90vh;
}

.submitBtn {
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background-color: tomato;
  color: #fff;
  border-radius: 5px;
}

.institutionList {
  list-style: none;
}

.headerText {
  margin-bottom: 1rem;
  /* border-bottom: 1px solid #d3d3d3; */
  color: var(--primary-color);
}

@media only screen and (max-width: 768px) {
  .gridBoder {
    border-right: none;
    padding: 0;
    height: auto;
  }
  .container {
    padding: 1rem;
    height: 99vh;
    margin: 0 auto;
    width: 100% !important;
  }
  .containerGrid {
    /* height: 110vh; */
    height: 59% !important;
    display: block !important;
  }
  .institutionName {
    font-size: 16px;
  }
  .studentItem {
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  .containerGrid {
    height: 110vh;
    display: block !important;
  }
  .institutionName {
    font-size: 16px !important;
  }
  .studentItem {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .institutionName {
    font-size: 16px !important;
  }
  .institutionName {
    font-size: 14px !important;
  }
  .studentItem {
    width: 100%;
  }
  .headerText {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 820px) {
  .containerGrid {
    /* height: 110vh; */
    height: 30% !important;
    display: block !important;
  }
  .gridBoder {
    height: max-content;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 768px) and (max-device-width: 1024px) {
  .institutionName {
    font-size: 14px !important;
  }
}


@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-device-width: 900px) and (max-device-width: 1280px) {
  .institutionName {
    font-size: 15px !important;
  }
}
