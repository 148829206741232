.headerText {
  color: var(--primary-color);
  margin-top: 25px;
}

.inputContainer {
  border: 1px solid #d3d3d3;
  padding: 0.5em;
  border-radius: 3px;
  /* width: min(80vw, 600px); */
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.item {
  background-color: var(--primary-color);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
  color: #fff;
}
.item .close {
  height: 20px;
  width: 20px;
  background-color: #fa5025;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  /* font-size: 18px; */
  cursor: pointer;
}

.input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
  background: none;
}

.saveBtn {
  padding: 0.6rem 2rem;
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  border: none;
  margin-block: 1rem;
  margin-right: 1rem;
  font-size: 1em;
  text-transform: uppercase;
}

.saveBtn:hover {
  background-color: #f3532b;
}

.resetBtn {
  padding: 0.6rem 2rem;
  background-color: grey;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  border: none;
  margin-block: 1rem;
  font-size: 1em;
  text-transform: uppercase;
}

.resetBtn:hover {
  background-color: rgb(88, 87, 87);
}

@media only screen and (max-width: 400px) {
  .inputContainer {
    margin-bottom: 1rem;
  }
  .headerText {
    margin-top: 21px;
  }
}

@media only screen and (max-width: 414px) {
  .inputContainer {
    margin-bottom: 1rem;
  }
  .headerText {
    margin-top: 21px;
  }
}

@media only screen and (max-width: 768px) {
  .headerText {
    margin-top: 21px;
  }
  .input {
    margin-bottom: -8px;
  }
}
