.container {
  padding: 2rem;
}

.togglers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.toggleItem h3 {
  margin-bottom: 0.5rem;
  text-align: center;
}

.toggleItemComment h3 {
  margin-bottom: 0.5rem;
  text-align: center;
}

.icon {
  margin-right: 10px;
}

.input {
  padding: 0.5rem;
}

.toogleBtnOne {
  padding: 15px;
  margin-left: -8px;
}

.toogleBtnTwo {
  padding: 15px;
}
.scDivider {
  margin-right: 10px !important;
  margin-left: 10px !important;
  border-bottom: 1px solid #c4c4c4 !important;
  height: 30px !important;
}

.video {
  width: 100%;
  background-color: grey;
  height: 18rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video span {
  color: #fff;
}

.youtubeVideo {
  width: 100%;
  height: 20rem;
  margin-bottom: 1rem;
}

.actions {
  padding-inline: 2rem;
  margin-bottom: 1rem;
}

.supportText {
  text-align: center;
  font-size: 1em;
  font-weight: 500;
}

.commenText {
  display: flex;
  margin-left: 15px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  .inputForm {
    width: 100%;
    margin-top: 200px;
  }
  .togglers {
    display: block;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .textItem {
    font-size: 13px;
  }
  .toggleItem {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .commenText {
    position: relative;
    margin-top: 70px;
    margin-bottom: 10px;
  }
  .toggleItemComment {
    margin-bottom: 0.5rem;
    text-align: center;
    position: absolute;
    top: 148px;
  }
  .supportText {
    text-align: center;
    font-size: 11px;
    font-weight: 500;
  }

  .toogleBtnOne {
    float: left;
    margin-left: -7px;
    margin-bottom: 20px;
  }
  .toogleBtnTwo {
    margin-left: -11px;
  }
}

@media only screen and (max-width: 320px) {
  .textItem {
    font-size: 7px;
  }
}

@media only screen and (max-width: 375px) {
  .inputForm {
    width: 100%;
  }
  .container {
    padding: 2rem;
  }
  .textItem {
    font-size: 9px !important;
  }
}

@media only screen and (max-width: 390px) {
  .container {
    padding: 1rem;
  }
  .textItem {
    font-size: 12px;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .inputForm {
    width: 100%;
    margin-top: 100px;
  }
  .toogleBtnOne {
    float: left;
    margin-left: -7px;
    margin-bottom: 20px;
  }

  .toggleItemComment {
    margin-bottom: 0.5rem;
    text-align: center;
    position: absolute;
    top: 148px;
  }
  .toogleBtnTwo {
    margin-left: -7px;
  }
  .commenText {
    position: relative;
    margin-top: 97px;
    margin-left: 8px;
    margin-bottom: 20px;
  }
}
