.main {
  padding-top: 1rem;
}

.logo {
  width: 100%;
  max-height: 150px;
  margin-bottom: 30px;
}

.ourMissionDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ourMissionDiv img {
  width: 100%;
  max-height: 600px;
}

@media (max-width: 1366px) {
  .logo {
    width: 100%;
    max-height: 110px;
    margin-bottom: 30px;
  }
  .ourMissionDiv img {
    width: 100%;
    max-height: 500px;
  }
}

@media (max-width: 1280px) and (min-height: 720px) {
  .ourMissionDiv img {
    width: 100%;
    max-height: 460px;
  }
}

@media (max-width: 1280px) and (min-height: 800px) {
  .ourMissionDiv img {
    width: 100%;
    max-height: 900px;
  }
}

@media (max-width: 1280px) and (min-height: 1024px) {
  .ourMissionDiv img {
    width: 100%;
    min-height: 750px;
  }
}

@media (max-width: 1536px) and (min-height: 864px) {
  .logo {
    width: 100%;
    max-height: 110px;
    margin-bottom: 30px;
  }
  .ourMissionDiv img {
    width: 100%;
    min-height: 100px;
  }
}
