.container {
  height: calc(100vh - var(--navbar-height));
  width: 6rem;
  display: flex;
  flex-direction: column;
}

.control {
  flex: 1;
  padding: 1rem;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.control label {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.active {
  background-color: darkblue;
}

@media only screen and (max-width: 1052px) {
  .container {
    flex-direction: row;
    height: 5rem;
    width: 100%;
  }
  .control {
    border-right: 1px solid whitesmoke;
  }
  .control label {
    font-size: 0.7em;
  }
}
