.container {
  padding: 4rem;
}

.container h1 {
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: "Avenir", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
}

.container h2 {
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: "Avenir", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.container h3 {
  font-family: "Avenir", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 20px;
}

.overall {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 2rem;
  }
  .container h1 {
    margin-bottom: 1rem;
    font-weight: 400;
    font-family: "Avenir", sans-serif;
    font-style: normal;
    font-size: 25px;
    line-height: 43px;
    text-align: center;
  }
  .container h2 {
    font-family: "Avenir", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
  }
  .container h3 {
    font-family: "Avenir", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  .wrapper {
    display: block;
    flex-direction: row;
    justify-content: space-between;
  }
  .container h1 {
    text-align: center !important;
  }
  .container h2 {
    font-family: "Avenir", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center !important;
  }
  .container h3 {
    font-family: "Avenir", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center !important;
  }
  .ratingStar {
    text-align: center !important;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 320px) {
  .container h2 {
    font-family: "Avenir", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center !important;
  }
  .ratingStar {
    text-align: center !important;
  }
}
