.container {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    right: 10px;
    margin: 10px;
    animation: slide 0.7s forwards ease-in-out;
    width: 400px;
    z-index: 9999;
  }
  
  .title,
  .body {
    color: #fff;
  }
  
  @keyframes slide {
    from {
      right: -100%;
    }
    to {
      right: 0;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .container {
        width: 250px;
    }
}