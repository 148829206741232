.details {
  padding-left: 3rem;
}

.teacherProfile {
  display: flex;
  padding-block: 1.5rem;
  align-items: center;
}

.teacherImg {
  height: 7rem;
  width: 7rem;
  margin-right: 2rem;
  object-fit: cover;
}

.stats {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.5rem;
}

.stat {
  padding: 1rem;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
}

.statValue {
  font-size: 15px;
}

.statLabel {
  font-size: 0.8em;
  font-weight: 300;
}

.bigText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 500px; */
}

.menuConatiner {
  width: 100%;
  /* transform: translateX(-15%) translateY(32%); */
}

@media only screen and (max-width: 768px) {
  .nameText {
    font-size: 1rem;
  }
  .institutionName {
    font-size: 11px;
  }
  .actionBtn {
    margin-bottom: 18px !important;
  }
  .stat {
    width: 50%;
  }
  .details {
    padding-left: initial;
    margin-bottom: 30px;
  }
  .teacherImg {
    height: 4rem;
    width: 4rem;
    margin-right: 2rem;
    object-fit: cover;
  }
  .stats {
    display: flex;
    margin-bottom: 1rem;
    gap: 0.5rem;
    flex-wrap: nowrap;
    flex-direction: row;
  }
  .statValue {
    font-size: 22px;
  }
  .bigText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 42%;
  }
  .deleteMainDiv {
    height: 20px;
    width: 61px;
    position: static;
    margin-top: 2px;
    background: #c4c4c4;
    float: right;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .searchContainer {
    display: block !important;
    margin-bottom: 20px;
    align-items: center;
    margin-top: 20px;
    padding-left: 3rem;
  }
  .hTag {
    text-align: center;
    margin-bottom: 20px;
  }
}

.searchContainer {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  margin-top: 20px;
  padding-left: 3rem;
}

.deleteMainDiv {
  height: 20px;
  width: 61px;
  position: static;
  margin-top: 2px;
  background: #c4c4c4;
  float: right;
  cursor: pointer;
}

.commentConatiner {
  border-radius: 0px;
  background: #c4c4c4;
  padding: 13px 14px;
  display: block;
  clear: both;
}

@media only screen and (max-width: 1024px) {
  .statValue {
    font-size: 11px;
  }
  .nameText {
    font-size: 16px;
  }
  .btnDiv {
    display: flex;
  }
  .muteBtn {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1280px) {
  .statValue {
    font-size: 11px;
  }
  .nameText {
    font-size: 16px;
  }
  .btnDiv {
    display: flex;
  }
  .muteBtn {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .statValue {
    font-size: 16px;
  }
  .searchContainer {
    display: block;
    margin-bottom: 20px;
    align-items: center;
    margin-top: 20px;
    padding-left: 0 !important;
  }
  .hTag {
    text-align: center;
    margin-bottom: 19px;
  }
  .noRecordText {
    padding: 4rem;
    text-align: center;
    font-size: 1rem !important;
  }
  .deleteMainDiv {
    height: 20px;
    width: 61px;
    position: static;
    margin-top: 2px;
    background: #c4c4c4;
    float: right;
    cursor: pointer;
    margin-bottom: 30px;
  }
}

.noRecordText {
  padding: 4rem;
  text-align: center;
  font-size: 2rem;
}

@media only screen and (max-width: 320px) {
  .statValue {
    font-size: 10px;
  }
  .stat {
    width: 25%;
  }
  .inputTag {
    border-radius: 30px;
    width: 93% !important;
  }
  .hTag {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 390px) {
  .statValue {
    font-size: 12px;
  }
  .stat {
    width: 25%;
  }
}

@media only screen and (max-width: 280px) {
  .statValue {
    font-size: 10px;
  }
  .stat {
    width: 25%;
  }
}

@media only screen and (max-width: 600px) {
  .hTag {
    text-align: center;
  }
}

@media only screen and (max-width: 674px) {
  .hTag {
    text-align: center;
    margin-bottom: 20px;
  }
  .searchContainer {
    display: block;
    margin-bottom: 20px;
    align-items: center;
    margin-top: 20px;
    padding-left: 3rem;
  }
}

@media only screen and (max-width: 400px) {
  .deleteMainDiv {
    height: 20px;
    width: 61px;
    position: static;
    margin-top: 2px;
    background: #c4c4c4;
    float: right;
    cursor: pointer;
    margin-bottom: 40px;
  }
  .searchContainer {
    display: block;
    margin-bottom: 20px;
    align-items: center;
    margin-top: 20px;
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 414px) {
  .searchContainer {
    display: block;
    margin-bottom: 20px;
    align-items: center;
    margin-top: 20px;
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 820px) {
  .deleteMainDiv {
    height: 20px;
    width: 61px;
    position: static;
    margin-top: 2px;
    background: #c4c4c4;
    float: right;
    cursor: pointer;
    margin-bottom: 40px;
  }
}
