.headerText {
  color: rgb(73, 73, 73);
}

.teacher {
  padding: 1rem 0.5rem;
  border-bottom: 1px solid grey;
  display: flex;
  cursor: pointer;
}

.teacher:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.teacher label {
  font-size: 1em;
  margin-bottom: 0.2rem;
  font-weight: 500;
}

.teacher p {
  font-weight: 100;
}

.teacherImg {
  width: 4rem;
  height: 4rem;
  background-color: grey;
  border-radius: 50%;
  margin-right: 1rem;
}

.noDataDiv {
  padding-block: 2rem;
  text-align: center;
}

.noDataImg {
  /* width: 10rem;
    height: 14rem; */
  aspect-ratio: 1/1;
  width: 30%;
  object-fit: cover;
}

.noDataDiv p {
  font-weight: 100;
  color: grey;
  opacity: 0.3;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

@media only screen and (max-width: 280px) {
  .teacher {
    width: 100vw;
  }
}

@media only screen and (max-width: 400px) {
  .headerText {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .teacher {
    padding: 1rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .teacher {
    padding: 1rem 0;
  }
}
