.container {
  padding-top: 8rem;
  padding-inline: 5rem;
}

.left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.right {
  padding-right: 1rem;
}

.right h1 {
  font-size: 72px;
  text-align: right;
}

.form {
  width: 60%;
}

.inputContainer {
  margin-bottom: 0.5rem;
}

.inputWrapper {
  margin-bottom: 1rem;
}

.inputWrapper p {
  margin-top: 5px;
}

.inputContainer label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  margin-left: 15px;
}

.inputContainer input {
  padding: 1rem 0.5rem;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 1px solid grey;
  width: 100%;
}

.form button {
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  /* margin-left: 15px; */
}

.loginBtn {
  background-color: #f76843;
}

.googleSignInBtn {
  background-color: #1784e9;
}

.signUpLink {
  text-align: center;
  text-decoration: none;
  color: #000;
}

.signUpLinkWrapper {
  text-align: center;
  font-size: 24px;
}

@media only screen and (max-width: 820px) {
  .right {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .right {
    display: none;
  }
}

@media only screen and (max-width: 412px) {
  .container {
    padding: 2rem 0;
    height: 80vh;
  }

  .form {
    width: 80%;
  }
}
