.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--navbar-height));
  overflow: hidden;
}

.scrollContainer {
  height: 100vh; /* Full height of the viewport */
  display: flex;
  flex-direction: column;
}

.scrollableContent {
  flex: 1; /* Take up remaining space */
  overflow-y: auto; /* Allow vertical scrolling */
}

.scrollViewLectures, .scrollViewDetails, .scrollView {
  max-height: 85vh; /* Adjust based on design */
  overflow-y: auto;
  padding: 20px; /* Optional padding */
}


.left {
  flex: 1;
  overflow: scroll;
  height: calc(100vh - var(--navbar-height));
  overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.left::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.left {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .center {
  background-color: #87CA87;
} */
.right {
  flex: 1;
  height: 85vh;
}

.tabs {
  display: flex;
  flex-direction: row;
  /* background-color: #c4c4c4; */
  margin: 1rem;
  margin-left: -40px;
}

.tab:first-child {
  border-right: 1px solid grey;
}

.tabLeft {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tabRight {
  flex: 1;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scrollView {
  height: 108%;
  overflow: auto;
}

.scrollViewLectures {
  height: 108%;
  overflow: auto;
  margin-left: -49px;
}

.scrollViewDetails {
  height: 108%;
  overflow: auto;
  margin-left: -57px;
}

@media only screen and (max-width: 1800px) {
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -34px;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    margin-left: -30px;
  }
}

@media only screen and (max-width: 1700px) {
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -24px;
  }
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 0;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    /* margin-left: -15px; */
  }
}

@media only screen and (max-width: 1600px) {
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -24px;
  }
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 0;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    /* margin-left: -15px; */
  }
}

@media only screen and (max-width: 1536px) {
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 0;
  }
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -25px;
  }
}

@media only screen and (max-width: 1440px) {
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 2px;
  }
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -20px;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1366px) {
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: -5px;
  }
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -15px;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1280px) {
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 2px;
  }
  .scrollViewDetails {
    height: 108%;
    overflow: auto;
    margin-left: -20px;
  }
  .tabs {
    display: flex;
    flex-direction: row;
    /* background-color: #c4c4c4; */
    margin: 1rem;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .scrollViewLectures {
    height: 108%;
    overflow: auto;
    margin-left: 7px;
  }
}

.playlistMainDiv {
  height: calc(100vh - 175px);
  overflow: auto;
}

@media only screen and (max-width: 1052px) {
  .container {
    flex-direction: column;
    height: auto;
  }
  .left {
    padding-bottom: 2rem;
  }
}
