/* .container {
    height: 70vh;
    background-color: red;
    overflow: scroll;
} */

.dashboardItem {
  border-right: 1px solid grey;
  height: 100%;
  overflow: auto;
}

.headerText {
  border-bottom: 1px solid grey;
  padding: 0.5rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "Avenir", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-left: -1px;
}

/* Search component */

.container {
  display: flex;
  align-items: center;
}

input {
  background-color: #f2f2f2;
  padding: 10px 5px;
  margin-inline: 1rem;
  width: 16rem;
  border: none;
  border-radius: 10px;
  margin-left: 7px;
}

@media only screen and (max-width: 768px) {
  .dashboardItem {
    border-right: none;
  }
  .container {
    display: block !important;
  }
  input {
    width: 90%;
  }
}

@media only screen and (max-width: 400px) {
  .dashboardItem {
    border-right: none;
    height: auto;
  }
  .container {
    display: block !important;
  }
  .scrollView {
    height: auto !important;
    overflow: none;
  }
}

@media only screen and (max-width: 820px) {
  .dashboardItem {
    border-right: 1px solid grey;
    height: 70%;
    overflow: auto;
  }
}

@media only screen and (max-width: 414px) {
  .dashboardItem {
    border-right: 1px solid grey;
    height: 92%;
    overflow: auto;
  }
}
