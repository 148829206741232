.liStyle {
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding: 5px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 10px;
}

.liStyle:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}

.dialogContent {
  overflow-y: scroll;
}

/* Add play list style */
.ulDiv {
  padding: 0;
  max-height: 9.5em; /* 1.5 x 3 */
  overflow-y: auto;
}

.liAddList {
  border-radius: 5px;
  margin-bottom: 0.5rem;
  padding: 5px;
}
