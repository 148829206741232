.container {
  padding: 1rem 5rem;
}

.headerText {
  margin-bottom: 1rem;
  /* border-bottom: 1px solid #d3d3d3; */
  color: var(--primary-color);
}

.institutionList {
  list-style: none;
}

.institutionItem {
  border-bottom: 1px solid #d3d3d3;
  padding: 1rem 0.5rem;
  cursor: pointer;
}

.institutionItem:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.submitBtn {
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  background-color: tomato;
  color: #fff;
  border-radius: 5px;
}

.stats {
  display: flex;
  margin-bottom: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.stat {
  padding: 1rem;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.stat:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.statValue {
  font-size: 1.4em;
  padding-bottom: 0.5rem;
  color: rgb(70, 70, 70);
}

.statLabel {
  font-size: 0.8em;
  font-weight: 300;
}

.live::before {
  content: "\A";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  display: inline-block;
  margin-right: 0.5rem;
}

.gridBoder {
  border-right: 1px solid rgb(211, 211, 211);
}

.gridStudentListBoder {
  border-right: 1px solid rgb(211, 211, 211);
  height: 100%;
  overflow: auto;
}

.studentItem {
  border-bottom: 1px solid #d3d3d3;
  padding: 1rem 0.5rem;
  cursor: pointer;
  display: flex;
}

.studentItem span {
  margin-left: 1rem;
}

.studentItem:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.containerGrid {
  height: 90vh;
}

@media only screen and (max-width: 768px) {
  .gridBoder {
    border-right: none;
  }
  .stat {
    padding: 1rem;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45%;
  }
  .container {
    padding: 1rem;
    height: 90vh;
    margin: 0 auto;
    width: 100% !important;
  }
  .containerGrid {
    height: 175vh;
    display: block !important;
  }
  .headerText {
    margin-top: 21px;
  }
  .addBtn {
    margin-top: 16px;
  }
  .institutionItem {
    width: 100%;
  }
  .studentItem {
    width: 100%;
  }
  .institutionList {
    list-style: none;
    width: 100%;
  }
  .stat {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    padding: 1rem;
    height: 90vh;
    margin: 0 auto;
    width: 100% !important;
  }
  .institutionItem {
    width: 100%;
  }
  .studentItem {
    width: 100%;
  }
  .headerText {
    margin-top: 21px;
  }
  .addBtn {
    margin-top: 16px;
  }
  .institutionList {
    list-style: none;
    width: 100%;
  }
  .containerGrid {
    height: 175vh;
    display: block !important;
  }
  .stat {
    margin-bottom: 10px;
  }
}

.addBtn {
  cursor: pointer;
}

@media only screen and (max-width: 280px) {
  .institutionItem {
    width: 100vw;
  }
  .studentItem {
    width: 100vw;
  }
  .headerText {
    margin-top: 21px;
  }
  .addBtn {
    margin-top: 16px;
  }
  .institutionList {
    list-style: none;
    width: 100%;
  }
  .containerGrid {
    height: 175vh;
    display: block !important;
  }
  .stat {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .containerGrid {
    height: 175vh;
    display: block !important;
  }
  .headerText {
    margin-top: 21px;
  }
  .stat {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 390px) {
  .containerGrid {
    height: 145vh;
    display: block !important;
  }
  .institutionList {
    list-style: none;
    width: 100%;
  }
  .stat {
    margin-bottom: 10px;
  }
  .headerText {
    margin-top: 21px;
  }
}

@media only screen and (max-width: 820px) {
  .containerGrid {
    /* height: 175vh; */
    display: block !important;
  }
  .institutionList {
    list-style: none;
    width: 100%;
  }
  .stat {
    margin-bottom: 10px;
  }
  .headerText {
    margin-top: 21px;
  }
}
