.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--navbar-height));
  overflow: hidden;
}

.left {
  flex: 1;
  overflow: scroll;
  height: calc(100vh - var(--navbar-height));
  overflow-x: scroll;
}

.left::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.left {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.center {
  background-color: #1784e9;
}

.scrollView {
  height: calc(100vh - 50px);
  overflow: auto;
}

.right {
  flex: 1;
  height: 85vh;
}

.playlistMainDiv {
  height: calc(100vh - 100px);
  overflow: auto;
}
@media only screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: row;
    height: inherit;
    overflow: hidden;
  }
  .scrollView {
    height: auto;
    overflow: auto;
  }
}

@media only screen and (max-width: 1052px) {
  .container {
    flex-direction: column;
  }
  .left {
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    height: inherit;
    overflow: hidden;
  }
  .left {
    padding-bottom: 3rem;
  }
}

@media only screen and (max-width: 820px) {
  .container {
    height: inherit;
    overflow: hidden;
  }
  .scrollView {
    height: auto;
    overflow: auto;
  }
}
